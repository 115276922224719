/* eslint-disable no-script-url */
import React, { useEffect, useLayoutEffect } from 'react';
import { Link, graphql, PageProps } from 'gatsby';
import { Flex, Label, Display, Para, Button } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import Layout from '@containers/Layout';
import {
  logItem,
  startNewSession,
  startNewPairShareSession,
} from '@slices/loggingSlice';
import useTranslation from '@hooks/useTranslation';
import BodyWrapper from '@components/BodyWrapper';
import {
  gameHasStarted,
  resetGame,
  setCurrentLanguage,
} from '@slices/gameStateSlice';
import { initializeRoutes } from '@slices/routeSlice';
import useSound from '@hooks/useSound';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import Seo from '@containers/Seo';
import AnimatedIntroImageWrapper from '@components/AnimatedIntroImageWrapper';
import getSymbol from '@utils/getSymbol';
import useUserLocalLanguage from '@hooks/useUserLocalLanguage';
import BackgroundImage from '@components/BackgroundImage';
import { WINDOW_HASH } from '@sharedConstants';
import useHasMounted from '@hooks/useHasMounted';
import DeactivationModal from '@containers/DeactivationModal';

const StartButton = styled(Button).attrs({
  forwardedAs: Link,
})(
  css({
    alignSelf: 'center',
    textDecoration: 'none',
  }),
);

const CenterContentWrapper = styled(Flex)(
  css({
    isolation: 'isolate',
    mt: 2,
    flex: [0.2, 1, 1],
    flexDirection: 'column',
  }),
);

const BottomContentWrapper = styled(Flex)(
  css({
    mt: 4,
    isolation: 'isolate',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
  }),
);

function isDeactivated(version: string): boolean {
  const code = version.replace('#', '');
  return new Set([
    'sc5ze8',
    '8njgrr',
    'idfmut',
    'o0acqg',
    'l87p07',
    '49pmvm',
    '0l01zx',
    'pif7yw',
    'twdm4u',
    'gcdnd0',
    'kn9zva',
    'h8wxs6',
    '62ef96',
    'un0rrl',
    'dvjzj2',
    'pbp1di',
    '18f004',
    'kb3ox4',
    'mwo49x',
    '4nph2e',
    'tzbhjt',
    'sbb6dl',
    '9cb4b4',
    'dhx8py',
    'yxnfrj',
  ]).has(code);
}

const IndexPage = ({
  location,
  data,
}: PageProps<Queries.IndexPageQuery>): JSX.Element => {
  // this hook must always fire first, and we also need to an log event for language change
  // do do that we must wait until we have a session. Its a little redundant, but we have to trigger the setlang event again once we have a valid session (seen below)
  useUserLocalLanguage();
  const hasMounted = useHasMounted();
  const { allRoutes, allOriginalRoutes } = useAppSelector(state => state.route);
  // this must be valid (its a random str to signify session)
  const { sessionId } = useAppSelector(state => state.logging);
  const { currentLanguage, defaultLanguage, isPairShareEnabled } =
    useAppSelector(state => state.gameState);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { playSound } = useSound();
  const mainPageTitleSymbol = getSymbol(data.mainPageTitleSymbol);
  const mainPageBgSymbol = getSymbol(data.mainPageBgSymbol);

  useEffect(() => {
    // a safety precaution for if you pressed back midgame and ended up here, or reset after finishing
    dispatch(resetGame());
    // first thing is to provision a session for the user
    // this should happen each time
    console.log('start new session');
    if (isPairShareEnabled === true) {
      console.log('starting a new pair share session');
      dispatch(startNewPairShareSession({ language: currentLanguage }));
    } else {
      dispatch(startNewSession({ language: currentLanguage }));
    }
    // init our routes here, we use original in case you came to this page having already started game
    dispatch(initializeRoutes({ routes: allOriginalRoutes }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPairShareEnabled]);

  useLayoutEffect(() => {
    // this is so we can send the lang change event once we gain a session (if applicable)
    if (sessionId) {
      // do session contingent stuff here, like setting a users language if it is mismatched from default due to hook
      if (currentLanguage !== defaultLanguage) {
        dispatch(
          setCurrentLanguage({
            setTo: currentLanguage,
            eventType: 'auto_toggle_lang',
          }),
        );
      }
    }
    // we really do not want this to double fire whenever language changes, we only care when session id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage, dispatch, sessionId]);

  return (
    <Layout
      shouldAnimate={false}
      location={location}
      sx={{
        backgroundColor: 'primary',
      }}
    >
      {mainPageBgSymbol && (
        <BackgroundImage
          imageData={mainPageBgSymbol.data}
          imageType={mainPageBgSymbol.type}
        />
      )}
      <BodyWrapper overflow="hidden">
        <Flex flex={1} />
        {isDeactivated(WINDOW_HASH) && (
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          <DeactivationModal isOpen onClose={() => { }} />
        )}
        <CenterContentWrapper>
          {mainPageTitleSymbol ? (
            <AnimatedIntroImageWrapper
              overrideImageStyling={{
                mb: 5,
              }}
              imageData={mainPageTitleSymbol.data}
              imageType={mainPageTitleSymbol.type}
            />
          ) : (
            <Flex flex={0.5} />
          )}
          <Display mt={2} fontWeight={900} variant="gameTitle">
            {t('Main Title')}
          </Display>
          <Para as="div" mt={2} px={5} variant="p2" isBold={false}>
            <CustomMdxRenderer>{t('Main Tagline', true)}</CustomMdxRenderer>
          </Para>
        </CenterContentWrapper>
        <BottomContentWrapper>
          {/* when we ssg or have no sessionId, we want to display a loading spinner */}
          {hasMounted && sessionId ? (
            <StartButton
              data-cy="start"
              state={{ cameFromEnd: false }}
              to={allRoutes[0]?.url + WINDOW_HASH}
              replace
              onPress={() => {
                dispatch(
                  logItem({
                    collection_name: 'events',
                    event_type: 'click',
                    target: 'start button',
                  }),
                );
                playSound('Button');
                dispatch(gameHasStarted());
              }}
            >
              <Label variant="l1">{t('Start Button')}</Label>
            </StartButton>
          ) : (
            <Flex flexDirection="column" alignItems="center">
              <Flex
                sx={{
                  '--spinner-color': e => e.colors.text,
                  '--spinner-width': ['60px', '60px', '80px'],
                  '--spinner-height': ['60px', '60px', '80px'],
                }}
                className="lds-ring"
              >
                <div />
                <div />
                <div />
                <div />
              </Flex>
              {/* this is done bc at this junction we have no react to work with */}
              <StartButton
                variant="secondary"
                className="fade-in"
                to="javascript:window.location.reload()"
              >
                {t('Reload')}
              </StartButton>
            </Flex>
          )}
        </BottomContentWrapper>
      </BodyWrapper>
    </Layout>
  );
};

// define some common gql fragment accessor heres as well
export const query = graphql`
  fragment SvgGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childSvg {
                  content {
                    data
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment AbsoluteGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                publicURL
                name
              }
            }
          }
        }
      }
    }
  }

  fragment GatsbyImageGetFragment on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }

  fragment GatsbyImageGetFragmentNoPlaceholder on AirtableConnection {
    nodes {
      data {
        Content {
          data {
            Symbol {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }

  query IndexPage {
    mainPageTitleSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Main Title" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
    mainPageBgSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;
export default IndexPage;

export const Head = () => <Seo />;
